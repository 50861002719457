import React, { useState, useEffect } from 'react';
// @material-ui/core components

import { FormattedMessage } from 'gatsby-plugin-intl';

import Button from 'components/CustomButtons';

import { Box, Typography } from '@material-ui/core';
import useStyles from './styles';

const DeletePrivateServerCompleted = ({ privateServer, action }) => {
  const classes = useStyles();
  if (!privateServer) return null;

  const { name, region } = privateServer;

  return (
    <>
      <div className={classes.text}>
        <Typography>
          <FormattedMessage
            id="app.privateServer.deleted"
            defaultMessage="Server {name} at region {region} deleted!"
            values={{ name, region }}
          />
        </Typography>
      </div>
      <Box justifyContent="center">
        <Button round color="google" onClick={action}>
          <FormattedMessage id="button.ok" />
        </Button>
      </Box>
    </>
  );
};

export default DeletePrivateServerCompleted;
