import React from 'react';
import { Link, FormattedMessage, navigate } from 'gatsby-plugin-intl';
// @material-ui/core components
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
// import Button from 'components/CustomButtons';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '@allconnect/store/lib/user/user.selectors';
import { loadServerStart } from '@allconnect/store/lib/server/server.actions';
import { selectPublicServers } from '@allconnect/store/lib/server/server.selectors';
import PublicServerList from './PublicServer/PublicServerList';
import FreeServerList from './PublicServer/FreeServerList';
const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    lineHeight: '0.1em',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: '20px',
    marginBottom: '0px',
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    margin: '10px 0 20px',
  },
  cardTitle: {
    textDecoration: 'none',
    fontSize: '1.1rem',
    fontWeight: 500,
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
  },
  body: {
    padding: '0px',
  },
  span: {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: '10px',
  },
}));

const ServerCard = ({ currentUser }) => {
  if (!currentUser) {
    return null;
  }
  const classes = useStyles();

  return (
    <Card>
      <CardHeader color="success" plain className={classes.cardTitle}>
        <FormattedMessage
          id="app.vpnServer.title"
          defaultMessage="VPN Servers"
        />
      </CardHeader>
      <CardBody className={classes.body}>
        {/* <Button
          round
          color="google"
          onClick={() => {
            navigate('/app/profilexml');
          }}
        >
          <FormattedMessage
            id="app.vpnServer.profileXml"
            defaultMessage="Update Profile.Xml"
          />
        </Button> */}
        <Typography className={classes.span}>
          {isMobile ? (
            <FormattedMessage
              id="app.vpnServer.connect.mobile"
              defaultMessage="Select region and click the button to launch AnyConnect"
            />
          ) : (
            <FormattedMessage
              id="app.vpnServer.connect.nonmobile"
              defaultMessage="Select region and scan QR code to get connected"
            />
          )}
        </Typography>
        {currentUser.type === 'free' ? (
          <FreeServerList />
        ) : (
          <PublicServerList />
        )}
        {/* <PublicServerList /> */}
      </CardBody>
    </Card>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  publicServers: selectPublicServers,
});

const mapDispatchToProps = dispatch => ({
  loadServerStart: () => dispatch(loadServerStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServerCard);
