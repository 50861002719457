import React, { useState, useEffect } from 'react';
// @material-ui/core components
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Button from 'components/CustomButtons';
import useStyles from './styles';

const BootingPrivateServer = ({ counter, getServer }) => {
  const classes = useStyles();
  return (
    <List>
      <ListItem>
        <Typography className={classes.text}>
          <FormattedMessage
            id="app.privateServer.booting"
            defaultMessage="Setting up your private server, it could take couple minutes, please wait. Refresh in {counter}"
            values={{ counter }}
          />
        </Typography>
      </ListItem>
      <Button
        round
        color="google"
        onClick={() => {
          getServer();
        }}
      >
        <FormattedMessage id="button.refresh" defaultMessage="Refresh" />
      </Button>
    </List>
  );
};

export default BootingPrivateServer;
