import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';

import {
  requestServerStart,
  getPublicServerStart,
} from '@allconnect/store/lib/server/server.actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser, selectRegion } from '@allconnect/store/lib/user';

import { Regions } from '@allconnect/models/lib/regions';

import ServerList from '../ServerList';
import { selectLang } from '@allconnect/store/lib/appStatus/appStatus.selectors';
import { getContinentsDisplayName, getRegionDisplayName } from 'utils/Regions';

const allRegions = Regions.allRegions();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: '20px',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);

const PublicServerList = ({ lang, currentUser }) => {
  if (!currentUser) {
    return null;
  }
  const classes = useStyles();
  const userName = currentUser.vpnUser;
  const allContinetCodes = Regions.allContinent().sort((a, b) => {
    if (a > b) return 1;
    if (b > a) return -1;
  });

  return (
    <>
      {allContinetCodes.map(c => {
        const regions: any = [];
        const regionsName = [];
        allRegions.map(region => {
          const r = new Regions(region.name);
          if (region.continentCode === c) {
            regionsName.push(region.name);
            regions.push({
              name: region.name,
              displayName: getRegionDisplayName(r.displayName(), lang),
              continentCode: r.region.continentCode,
              description: r.region.description,
              countryCode: r.region.countryCode,
            });
          }
        });

        return (
          <List
            key={c}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                {getContinentsDisplayName(c, lang)}
              </ListSubheader>
            }
            className={classes.root}
          >
            {regions.map(region => {
              const hostName = `${region.name}`;

              const deeplink =
                process.env.NODE_ENV === 'production'
                  ? `anyconnect:connect?host=${region.name}.getallconnect.com&prefill_username=${userName}`
                  : `anyconnect:connect?host=${region.name}-dev.getallconnect.com&prefill_username=${userName}`;
              return (
                <ServerList
                  key={region.name}
                  hostName={hostName}
                  deeplink={deeplink}
                  region={region}
                />
              );
            })}
          </List>
        );
      })}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentRegion: selectRegion,
  lang: selectLang,
});

const mapDispatchToProps = dispatch => ({
  requestServer: ({ region, publicServer, name }) =>
    dispatch(requestServerStart({ region, publicServer, name })),
  getPublicServerStart: name => dispatch(getPublicServerStart(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicServerList);
