import React, { useState, useEffect } from 'react';
// @material-ui/core components
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormattedMessage } from 'gatsby-plugin-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import RefreshIcon from '@material-ui/icons/Refresh';

import Tooltip from '@material-ui/core/Tooltip';
// core components
import copy from 'clipboard-copy';

import Button from 'components/CustomButtons';

import { Regions } from '@allconnect/models/lib/regions';
import ServerActionTypes from '@allconnect/store/lib/server/server.types';
import ServerStatus from 'components/UseInterval';
import RegionChip from '../RegionChip';
import { Form } from 'formik';
import { FormattedTimeParts } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: '0 10px',
  },
}));

const StartingPrivateServer = ({ currentRegion, action, cancelAction }) => {
  const classes = useStyles();
  // compute all supported regions
  const allRegions = Regions.allRegions().sort((a, b) => {
    if (a.continentCode > b.continentCode) {
      return 1;
    } else {
      return -1;
    }
  });
  const [newRegion, setRegion] = useState(currentRegion);
  return (
    <>
      <RegionChip
        selected={newRegion}
        regions={allRegions}
        onSelect={setRegion}
      />
      <Box my={3}>
        <Typography className={classes.text}>
          <FormattedMessage
            id="app.privateServer.launchConfirm"
            defaultMessage="Start your private server in region {region}?"
            values={{ region: newRegion }}
          />
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Box mx={2}>
          <Button
            round
            color="google"
            onClick={() => {
              action(newRegion);
            }}
          >
            <FormattedMessage id="button.submit" />
          </Button>
        </Box>
        <Box mx={2}>
          <Button round color="primary" onClick={cancelAction}>
            <FormattedMessage id="button.cancel" />
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default StartingPrivateServer;
