import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
// @material-ui/core components
import Typography from '@material-ui/core/Typography';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter';
import Button from 'components/CustomButtons';

import useStyles from './styles';

const CancelCompleted = ({ onAction }) => {
  const classes = useStyles();

  // user cancel subscription
  return (
    <Card>
      <CardHeader color="success" plain className={classes.cardTitle}>
        <FormattedMessage
          id="app.profile.cancelSub"
          defaultMessage="Cancel subscription"
        />
      </CardHeader>
      <CardBody>
        <Typography className={classes.text}>
          <FormattedMessage
            id="app.profile.cancelCompleted"
            defaultMessage="Cancel subscription"
          />
        </Typography>
      </CardBody>
      <CardFooter className={classes.footer}>
        <Button
          onClick={() => {
            onAction('INIT');
          }}
          round
          type="submit"
          color="google"
        >
          <FormattedMessage id="button.ok" defaultMessage="OK" />
        </Button>
      </CardFooter>
    </Card>
  );
};

export default CancelCompleted;
