import React, { useState, useEffect } from 'react';
import { Link, FormattedMessage, navigate } from 'gatsby-plugin-intl';
import { isMobile } from 'react-device-detect';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcone from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import SaveIcon from '@material-ui/icons/Save';
// core components
import Tooltip from '@material-ui/core/Tooltip';
import copy from 'clipboard-copy';
import FilterNoneIcon from '@material-ui/icons/FilterNone';

import * as yup from 'yup';
import { Formik } from 'formik';
import CustomInput from 'components/CustomInput/CustomInput';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter';

import Button from 'components/CustomButtons';
import { clearError } from '@allconnect/store/lib/appStatus/appStatus.actions';
import {
  onGetP12Start,
  changeVpnPasswordStart,
  resetP12Link,
} from '@allconnect/store/lib/user/user.actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectCurrentUser,
  selectP12,
} from '@allconnect/store/lib/user/user.selectors';
import { loadUserStart } from '@allconnect/store/lib/user/user.actions';
import { selectStatus } from '@allconnect/store/lib/appStatus/appStatus.selectors';
import UserActionTypes from '@allconnect/store/lib/user/user.types';
import QRCode from 'components/QRCode';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography/Typography';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  button: {
    margin: theme.spacing(1),
    flex: 1,
  },
  footer: {
    justifyContent: 'center',
  },
  cardTitle: {
    textDecoration: 'none',
    fontSize: '1.1rem',
    fontWeight: 500,
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
  },
  root: {
    // display: 'flex',
    // flexDirection: 'column',
    width: '100%',
    margin: '0 auto',
  },
  listSection: {
    listStyle: 'none',
    backgroundColor: 'inherit',
    // display: 'flex',
    // flexFlow: 'wrap',
    width: '100%',
  },
  ul: {
    color: theme.palette.text.primary,
    listStyle: 'none',
    backgroundColor: 'inherit',
    padding: 0,
  },
  text: {
    color: theme.palette.text.primary,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const domain =
  process.env.SHORTEN_URL_DOMAIN || 'https://d.getallconnect.com/u/';

// FreeRegister component
const VpnUserCard = ({
  currentUser,
  p12link,
  resetP12,
  appStatus,
  getUserP12,
  resetStatus,
  changeVpnPassword,
  loadUser,
}) => {
  if (!currentUser) {
    return null;
  }
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [changePassword, setChangePassword] = useState(null);
  const [copied, setCopied] = useState({});
  const [p12QRCode, setP12QRCode] = useState(() => {
    if (currentUser) {
      const encodeLink = encodeURIComponent(`${domain}${currentUser.email}`);
      return `anyconnect:import?type=pkcs12&uri=${encodeLink}`;
    } else {
      return null;
    }
  });

  const lgScreen = useMediaQuery('(min-width:500px)');

  useEffect(() => {
    if (p12link) {
      // const encodeLink = encodeURIComponent(`${domain}${currentUser.email}`);
      // const p12 = `anyconnect:import?type=pkcs12&uri=${encodeLink}`;
      // setP12QRCode(p12);
      if (window) {
        if (isMobile) {
          window.open(p12);
        } else {
          window.open(p12link);
        }
      }
      resetP12();
    }
  }, [p12link]);

  const { vpnUser, vpnPassword, p12 } = currentUser;

  const handleP12Link = () => {
    if (!p12link) {
      getUserP12();
    }
  };

  if (!vpnUser || !vpnPassword) {
    return null;
  }

  const handleChangePassword = values => {
    changeVpnPassword({ vpnPassword: values.password });
  };
  useEffect(() => {
    if (appStatus === UserActionTypes.CHANGE_VPNPASSWORD_SUCCESS) {
      resetStatus();
      setChangePassword('COMPLETED');
    }
  }, [appStatus]);

  if (changePassword) {
    const initValues = {
      password: '',
      password1: '',
    };

    const formSchema = yup.object().shape({
      password: yup.string().required('Password is required'),
      password1: yup.string().required('Password is required'),
    });

    return (
      <Card>
        <CardHeader color="success" plain className={classes.cardTitle}>
          <FormattedMessage
            id="app.vpnUser.changePassword"
            defaultMessage="Change VPN Login Password"
          />
        </CardHeader>
        {changePassword === 'START' ? (
          <Formik
            initialValues={initValues}
            validationSchema={formSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              handleChangePassword(values);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form>
                <CardBody>
                  <CustomInput
                    labelText={
                      <FormattedMessage
                        id="app.vpnUser.newPassword"
                        defaultMessage="New VPN Password"
                      />
                    }
                    error={touched.password && errors.password ? true : false}
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'password',
                      name: 'password',
                      onChange: handleChange,
                      onBlur: handleBlur,
                      value: values.password,
                    }}
                  />
                  <CustomInput
                    labelText={
                      <FormattedMessage
                        id="app.vpnUser.confirmPassword"
                        defaultMessage="Confirm Password"
                      />
                    }
                    id="password1"
                    error={touched.password1 && errors.password1 ? true : false}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'password',
                      name: 'password1',
                      onChange: handleChange,
                      onBlur: handleBlur,
                      value: values.password1,
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.footer}>
                  <Box mx={2}>
                    <Button
                      disabled={
                        isSubmitting || values.password !== values.password1
                      }
                      onClick={e => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      round
                      type="submit"
                      color="google"
                    >
                      <FormattedMessage id="button.submit" />
                    </Button>
                  </Box>
                  <Box mx={2}>
                    <Button
                      disabled={isSubmitting}
                      onClick={() => {
                        setChangePassword(null);
                      }}
                      round
                      type="submit"
                      color="primary"
                    >
                      <FormattedMessage id="button.cancel" />
                    </Button>
                  </Box>
                </CardFooter>
              </form>
            )}
          </Formik>
        ) : (
          <CardBody className={classes.footer}>
            <Box my={3}>
              <Typography className={classes.text}>
                <FormattedMessage
                  id="app.vpnUser.changedPassword"
                  defaultMessage="VPN Password Changed Successfully"
                />
              </Typography>
            </Box>
            <Button
              onClick={e => {
                e.preventDefault();
                setChangePassword(null);
              }}
              round
              type="submit"
              color="primary"
            >
              <FormattedMessage id="button.ok" />
            </Button>
          </CardBody>
        )}
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader color="success" plain className={classes.cardTitle}>
        <FormattedMessage
          id="app.vpnUser.setting"
          defaultMessage="VPN User Setting"
        />
      </CardHeader>
      <CardBody className={classes.listSection}>
        <List className={classes.ul}>
          <ListItem>
            <Tooltip
              title={
                copied[vpnUser] ? (
                  <FormattedMessage
                    id="app.vpnUser.copied"
                    defaultMessage="Copied to clipboard"
                  />
                ) : (
                  <FormattedMessage
                    id="app.vpnUser.clickCopy"
                    defaultMessage="Click to copy to clipboard"
                  />
                )
              }
            >
              <ListItemText
                primary={
                  <FormattedMessage
                    id="app.vpnUser.name"
                    defaultMessage="Name:"
                  />
                }
                secondary={vpnUser}
                onClick={() => {
                  copy(vpnUser);
                  setCopied({
                    [vpnUser]: 'Copied to clipboard',
                  });
                }}
              />
            </Tooltip>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <FormattedMessage
                  id="app.vpnUser.connection"
                  defaultMessage="Max Connections:"
                />
              }
              secondary={
                currentUser.profile === 'host'
                  ? 'Unlimited'
                  : currentUser.profile === 'cert'
                  ? '10'
                  : '1'
              }
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary={
                <FormattedMessage
                  id="app.vpnUser.password"
                  defaultMessage="Password:"
                />
              }
              secondary={
                showPassword ? (
                  <>
                    {vpnPassword}
                    {'    '}
                    <VisibilityOffIcon
                      onClick={() => setShowPassword(pre => !pre)}
                    />
                  </>
                ) : (
                  <>
                    ****{'    '}
                    <VisibilityIcon
                      onClick={() => setShowPassword(pre => !pre)}
                    />
                    <Tooltip
                      title={
                        copied['password'] ? (
                          <FormattedMessage
                            id="app.vpnUser.copied"
                            defaultMessage="Copied to clipboard"
                          />
                        ) : (
                          <FormattedMessage
                            id="app.vpnUser.clickCopy"
                            defaultMessage="Click to copy to clipboard"
                          />
                        )
                      }
                    >
                      <FilterNoneIcon
                        onClick={() => {
                          copy(vpnPassword);
                          setCopied({
                            ['password']: 'Copied to clipboard',
                          });
                        }}
                      />
                    </Tooltip>
                  </>
                )
              }
            />
            <Button
              justIcon={lgScreen ? false : true}
              round
              color="google"
              onClick={() => {
                setChangePassword('START');
              }}
            >
              {lgScreen ? (
                <FormattedMessage id="button.change" />
              ) : (
                <EditIcone />
              )}
            </Button>
          </ListItem>
          {p12 && p12.password && (
            <ListItem>
              <ListItemText
                primary={
                  <div style={{ color: '#dd4b39', fontWeight: 700 }}>
                    <FormattedMessage
                      id="app.vpnUser.p12Password"
                      defaultMessage="P12 Import Password:"
                    />
                    <br />
                    {p12.password}
                    <Box mx={2}>
                      <Button
                        round
                        color="primary"
                        onClick={handleP12Link}
                        endIcon={p12 ? <SaveIcon /> : null}
                      >
                        {isMobile ? (
                          <FormattedMessage
                            id="app.vpnUser.p12Import"
                            defaultMessage="Click to Import"
                          />
                        ) : (
                          <FormattedMessage
                            id="app.vpnUser.p12Download"
                            defaultMessage="Download"
                          />
                        )}
                      </Button>
                    </Box>
                  </div>
                }
                // secondary={}
              />
              {p12QRCode && !isMobile && lgScreen && (
                <div>
                  <QRCode url={p12QRCode} />
                  {/* <p>
                    <FormattedMessage
                      id="app.vpnUser.p12QRCode"
                      defaultMessage="Scan QR Code using your cell phone to import client certificate"
                    />
                  </p> */}
                </div>
              )}
            </ListItem>
          )}
        </List>
      </CardBody>
      <CardFooter className={classes.footer}>
        {currentUser.profile === 'cert' &&
          currentUser.status === 'subscribed' &&
          !p12 && (
            <>
              <Button round color="google" onClick={loadUser()}>
                <FormattedMessage
                  id="button.refresh"
                  defaultMessage="Refresh"
                />
              </Button>
            </>
          )}
      </CardFooter>
    </Card>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  p12link: selectP12,
  appStatus: selectStatus,
});

const mapDispatchToProps = dispatch => ({
  resetP12: () => dispatch(resetP12Link()),
  getUserP12: () => dispatch(onGetP12Start()),
  resetStatus: () => dispatch(clearError()),
  changeVpnPassword: user => dispatch(changeVpnPasswordStart(user)),
  loadUser: () => dispatch(loadUserStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VpnUserCard);
