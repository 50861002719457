import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby-plugin-intl';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectCurrentUser } from '@allconnect/store/lib/user/user.selectors';

const PrivateRoute = ({ component: Component, path, currentUser, ...rest }) => {
  const redirect = path ? path : '/app/home';

  if (!currentUser) {
    // If the user is not logged in, redirect to the login page.
    navigate(`/login?redirect=${redirect}`);
    return null;
  }

  return <Component {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  currentUser: PropTypes.object, //current login user, null if is not login
  path: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps, null)(PrivateRoute);
