import React, { useState, useEffect } from 'react';
import { FormattedDate, FormattedMessage, navigate } from 'gatsby-plugin-intl';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

import Button from 'components/CustomButtons';
import useStyles from './styles';

const ChangePasswordCompleted = ({ onAction }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader color="success" plain className={classes.cardTitle}>
        <FormattedMessage
          id="app.profile.changePassword"
          defaultMessage="Change Password"
        />
      </CardHeader>

      <CardBody className={classes.footer}>
        <h2 className={classes.ul}>
          <FormattedMessage
            id="app.profile.passwordChangeSuccess"
            defaultMessage="Password Changed Successfully"
          />
        </h2>
        <Button
          onClick={() => {
            onAction('INIT');
          }}
          round
          type="submit"
          color="primary"
        >
          <FormattedMessage id="button.ok" defaultMessage="OK" />
        </Button>
      </CardBody>
    </Card>
  );
};

export default ChangePasswordCompleted;
