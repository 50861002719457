import React, { useState, useEffect } from 'react';
// @material-ui/core components
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';

import Tooltip from '@material-ui/core/Tooltip';
// core components
import copy from 'clipboard-copy';

import Button from 'components/CustomButtons';
import useStyles from './styles';
const PrivateServerStatus = ({ privateServer, action }) => {
  if (!privateServer) {
    return null;
  }
  const classes = useStyles();
  const intl = useIntl();

  const lgScreen = useMediaQuery('(min-width:500px)');
  const [copied, setCopied] = useState({});

  const { name, IP, state } = privateServer;

  return (
    <List>
      <ListItem>
        <Tooltip
          title={
            copied[name]
              ? copied[name]
              : intl.formatMessage({
                  id: 'app.clickToCopy',
                  defaultMessage: 'Click to copy to clipboard',
                })
          }
        >
          <ListItemText
            onClick={() => {
              copy(name);
              setCopied({
                [name]: intl.formatMessage({
                  id: 'app.copyToClipboard',
                  defaultMessage: 'Copied to clipboard',
                }),
              });
            }}
            primary={
              <div className={classes.text}>
                <FormattedMessage id="app.server.hostname" values={{ name }} />
              </div>
            }
            secondary={
              <>
                <FormattedMessage id="app.server.IP" values={{ IP }} />
                <br />
                <FormattedMessage
                  id="app.server.status"
                  values={{ status: state }}
                />
              </>
            }
          />
        </Tooltip>
        <Button
          justIcon={lgScreen ? false : true}
          round
          color="google"
          onClick={action}
        >
          {lgScreen ? (
            <FormattedMessage id="button.delete" defaultMessage="Delete" />
          ) : (
            <DeleteIcon />
          )}
        </Button>
      </ListItem>
    </List>
  );
};

export default PrivateServerStatus;
