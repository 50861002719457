import React, { useState, useEffect } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { isMobile } from 'react-device-detect';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import copy from 'clipboard-copy';
import Button from 'components/CustomButtons';
import QRCode from 'components/QRCode';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: '20px',
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);

function ServerList({ region, hostName, deeplink }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <ReactCountryFlag
            svg
            countryCode={region.countryCode}
            style={{
              fontSize: '2em',
              lineHeight: '2em',
            }}
          />
        </ListItemIcon>
        <Tooltip title={region.description}>
          <ListItemText primary={region.displayName} secondary={hostName} />
        </Tooltip>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {isMobile ? (
            <Button
              round
              color="google"
              onClick={() => {
                window.location = deeplink;
              }}
            >
              Launch AnyConnect to Connect
            </Button>
          ) : (
            <QRCode url={deeplink} />
          )}
        </div>
      </Collapse>
    </>
  );
}

export default ServerList;
