import React, { useState, useEffect } from 'react';
import { FormattedMessage, navigate } from 'react-intl';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import EditIcone from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/Person';
import TitleIcon from '@material-ui/icons/Title';

// core components
import * as yup from 'yup';
import { Formik, Field } from 'formik';
import iso3166 from 'iso3166-1';
import locale2 from 'locale2';
import moment from 'moment-timezone';

import { GridContainer } from '@jackywxd/shared-material-ui';
import { GridItem } from '@jackywxd/shared-material-ui';
import { CustomInput } from '@jackywxd/shared-material-ui';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';
// import { Button } from '@jackywxd/shared-material-ui';
import Button from 'components/CustomButtons';
import {
  onChangePasswordStart,
  cancelSubscriptionStart,
  updateUserStart,
} from '@allconnect/store/lib/user/user.actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '@allconnect/store/lib/user/user.selectors';
import { selectStatus } from '@allconnect/store/lib/appStatus/appStatus.selectors';
// @material-ui/icons
import UserActionTypes from '@allconnect/store/lib/user/user.types';
import { clearError } from '@allconnect/store/lib/appStatus/appStatus.actions';
import PhoneInput from './PhoneInput';
import { getCountryFromTimezone } from 'components/Timezone/timezone2countries';
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
  },
  paper: {
    paddingTop: theme.spacing(3),
    padding: theme.spacing(1),
    margin: 'auto',
    maxWidth: 400,
  },
  footer: {
    justifyContent: 'center',
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  button: {
    margin: theme.spacing(1),
    flex: 1,
  },
  root: {
    // display: 'flex',
    // flexDirection: 'column',
    width: '100%',
    margin: '0 auto',
  },
  listSection: {
    listStyle: 'none',
    backgroundColor: 'inherit',
    // display: 'flex',
    // flexFlow: 'wrap',
    width: '100%',
  },
  ul: {
    listStyle: 'none',
    backgroundColor: 'inherit',
    padding: 0,
  },
  inline: {
    // display: 'inline',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  flagControl: {
    padding: theme.spacing(1),
  },
}));

const processPhone = phone => {
  let mystring = phone
    .split(' ')
    .join('')
    .split('-')
    .join('')
    .split('(')
    .join('')
    .split(')')
    .join('');
  return mystring;
};

// FreeRegister component
const EditProfile = ({
  currentUser,
  appStatus,
  changePasswordStart,
  resetStatus,
  cancelSubscription,
  editProfile,
  setEditProfile,
  updateUser,
}) => {
  if (!currentUser) {
    return null;
  }

  const classes = useStyles();
  const lgScreen = useMediaQuery('(min-width:500px)');
  const userFlag = React.createRef();
  const { tz, phone, name, address } = currentUser;

  const [country, setCountry] = useState(
    tz ? getCountryFromTimezone(tz) : 'US'
  );

  const {
    email,
    type,
    status,
    plan,
    profile,
    expiredAt,
    subscription,
    last4,
  } = currentUser;

  let trialEnd;
  if (subscription) {
    const { trial_end, status } = subscription;
    if (status === 'trialing' && trial_end) {
      trialEnd = trial_end;
    }
  }

  const profileSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    phone: yup.string(),
    address: yup.object().shape({
      line1: yup.string(),
      city: yup.string(),
      country: yup.string(),
      postal_code: yup.string(),
      state: yup.string(),
    }),
  });
  const initValues = {
    name: name ? name : '',
    phone: phone ? phone : '',
    address: address
      ? address
      : {
          line1: '',
          postal_code: '',
          city: '',
          state: '',
          country: '',
        },
  };

  const handleEditProfile = values => {
    let profile = { ...values };
    let number;
    const { phone } = values;

    if (phone && phone !== '+') {
      const phoneNumber = parsePhoneNumberFromString(phone);
      if (phoneNumber && phoneNumber.number) {
        number = phoneNumber.number;
      } else {
        number = processPhone(phone);
      }
      profile = { ...values, phone: number };
    } else {
      delete profile.phone;
    }
    updateUser(profile);
  };

  useEffect(() => {
    initValues.address.country = country;
  }, [country]);

  useEffect(() => {
    if (
      editProfile === 'START' &&
      appStatus === UserActionTypes.UPDATE_USER_SUCCESS
    ) {
      resetStatus();
      setEditProfile('COMPLETED');
    }
  }, [appStatus, editProfile]);

  return (
    <Card>
      <CardHeader color="success" plain>
        Edit Profile
      </CardHeader>
      {editProfile === 'START' ? (
        <Formik
          initialValues={initValues}
          validationSchema={profileSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            handleEditProfile(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form>
              <CardBody className={classes.paper}>
                <CustomInput
                  labelText="Name*"
                  error={touched.name && errors.name ? true : false}
                  id="name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    name: 'name',
                    onChange: handleChange,
                    onBlur: handleBlur,
                    value: values.name,
                  }}
                />
                {errors.name && touched.name && errors.name}
                <Field name="phone">
                  {({ field, form }) => (
                    <PhoneInput field={field} form={form} label="Mobile" />
                  )}
                </Field>
                <CustomInput
                  labelText="Address"
                  id="adress"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    name: 'address.line1',
                    onChange: handleChange,
                    onBlur: handleBlur,
                    value: values.address.line1,
                  }}
                />
                <CustomInput
                  labelText="City"
                  id="city"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    name: 'address.city',
                    onChange: handleChange,
                    onBlur: handleBlur,
                    value: values.address.city,
                  }}
                />
                <CustomInput
                  labelText="Postal Code"
                  id="postal_code"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    name: 'address.postal_code',
                    onChange: handleChange,
                    onBlur: handleBlur,
                    value: values.address.postal_code,
                  }}
                />
                <CustomInput
                  labelText="State"
                  id="state"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    name: 'address.state',
                    onChange: handleChange,
                    onBlur: handleBlur,
                    value: values.address.state,
                  }}
                />
                <Field name="address.country">
                  {({ field, form }) => (
                    <ReactFlagsSelect
                      ref={userFlag}
                      searchable={true}
                      className={classes.flagControl}
                      defaultCountry={country}
                      onSelect={countryCode => {
                        form.setFieldValue(field.name, countryCode);
                        setCountry(countryCode);
                      }}
                    />
                  )}
                </Field>
              </CardBody>
              <CardFooter className={classes.footer}>
                <Button
                  disabled={isSubmitting}
                  onClick={e => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  round
                  type="submit"
                  color="google"
                >
                  Submit
                </Button>
                <Button
                  disabled={isSubmitting}
                  onClick={() => {
                    setEditProfile(null);
                  }}
                  round
                  type="submit"
                  color="primary"
                >
                  Cancel
                </Button>
              </CardFooter>
            </form>
          )}
        </Formik>
      ) : (
        <CardBody>
          <h2>Profile Updated Successfully</h2>
          <Button
            onClick={e => {
              e.preventDefault();
              setEditProfile(null);
            }}
            round
            type="submit"
            color="primary"
          >
            OK
          </Button>
        </CardBody>
      )}
    </Card>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  appStatus: selectStatus,
});

const mapDispatchToProps = dispatch => ({
  resetStatus: () => dispatch(clearError()),
  cancelSubscription: () => dispatch(cancelSubscriptionStart()),
  changePasswordStart: ({ oldPassword, newPassword }) =>
    dispatch(onChangePasswordStart({ oldPassword, newPassword })),
  updateUser: user => dispatch(updateUserStart(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
