import React from 'react';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
// core components
import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter';

import Button from 'components/CustomButtons';
import useStyles from './styles';
import Box from '@material-ui/core/Box';

// FreeRegister component
const ChangePassword = ({ onAction }) => {
  const classes = useStyles();
  const intl = useIntl();

  const initValues = {
    oldPassword: '',
    newPassword: '',
  };

  const formSchema = yup.object().shape({
    oldPassword: yup.string().required(
      intl.formatMessage({
        id: 'app.profile.oldPasswordRequired',
        defaultMessage: 'Old Password is required',
      })
    ),
    newPassword: yup.string().required(
      intl.formatMessage({
        id: 'app.profile.newPasswordRequired',
        defaultMessage: 'New Password is required',
      })
    ),
  });

  return (
    <Card>
      <CardHeader color="success" plain className={classes.cardTitle}>
        <FormattedMessage
          id="app.profile.changePassword"
          defaultMessage="Change Password"
        />
      </CardHeader>
      <Formik
        initialValues={initValues}
        validationSchema={formSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          onAction('CHANGE_PASSWORD_CONFIRM', values);
          setSubmitting(false);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <CardBody className={classes.text}>
              <Field
                component={TextField}
                fullWidth
                name="oldPassword"
                type="password"
                label={
                  <FormattedMessage
                    id="app.profile.oldPassword"
                    defaultMessage="Old Password"
                  />
                }
              />
              <br />
              <Field
                component={TextField}
                fullWidth
                type="password"
                label={
                  <FormattedMessage
                    id="app.profile.newPassword"
                    defaultMessage="New Password"
                  />
                }
                name="newPassword"
              />
            </CardBody>
            <CardFooter className={classes.footer}>
              <Box mx={2}>
                <Button
                  disabled={isSubmitting}
                  onClick={() => {
                    submitForm();
                  }}
                  round
                  type="submit"
                  color="google"
                >
                  <FormattedMessage id="button.submit" />
                </Button>
              </Box>
              <Box mx={2}>
                <Button
                  disabled={isSubmitting}
                  onClick={() => {
                    onAction('INIT');
                  }}
                  round
                  type="submit"
                  color="primary"
                >
                  <FormattedMessage id="button.cancel" />
                </Button>
              </Box>
            </CardFooter>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default ChangePassword;
