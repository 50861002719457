import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    lineHeight: '0.1em',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: '20px',
    marginBottom: '0px',
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    margin: '10px 0 20px',
  },
  cardTitle: {
    textDecoration: 'none',
    fontSize: '1.1rem',
    fontWeight: 500,
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
  },
  span: {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: '0 10px',
  },
  text: {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    fontSize: '1rem',
    padding: '10px',
  },
  server: {
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
