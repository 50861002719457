import React, { useEffect, useState } from 'react';
import { Link, FormattedMessage, navigate } from 'gatsby-plugin-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// @material-ui/core components
import { makeStyles, Theme } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';
import Button from 'components/CustomButtons';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter';

import { selectCurrentUser } from '@allconnect/store/lib/user/user.selectors';
import {
  selectPublicServers,
  selectPrivateServer,
} from '@allconnect/store/lib/server/server.selectors';
import { clearError } from '@allconnect/store/lib/appStatus/appStatus.actions';
import { selectLang } from '@allconnect/store/lib/appStatus/appStatus.selectors';
import ServerActionTypes from '@allconnect/store/lib/server/server.types';
import {
  loadServerStart,
  requestServerStart,
  getServerStart,
  deleteServerStart,
} from '@allconnect/store/lib/server/server.actions';
import UserActionTypes from '@allconnect/store/lib/user/user.types';
import { getRegionStart } from '@allconnect/store/lib/user/user.actions';
import { selectRegion } from '@allconnect/store/lib/user/user.selectors';
import { selectStatus } from '@allconnect/store/lib/appStatus/appStatus.selectors';
import ServerStatus from 'components/UseInterval';
import PrivateServerStatus from './PrivateServerStatus';
import LaunchPrivateServer from './LaunchPrivateServer';
import StartingPrivateServer from './StartingPrivateServer';
import BootingPrivateServer from './BootingPrivateServer';
import DeletePrivateServer from './DeletePrivateServer';
import DeletePrivateServerCompleted from './DeletePrivateServerCompleted';
import Upgrade from './Upgrade';
import ChangePlan from '../../ProfileCard/ChangePlan';

import ServerItem from './ServerItem';
import useStyles from './styles';

const PrivateServerCard = ({
  currentUser,
  privateServer,
  getServer,
  requestServer,
  deleteServer,
  resetStatus,
  currentRegion,
  appStatus,
  lang, // current Language settings
}) => {
  const [upgrade, setUpgrade] = useState('INIT');
  const classes = useStyles();

  // count down counter for re-fresh
  const [counter, setCounter] = useState(10);
  const [startPolling, setStartPolling] = useState(false);

  const [state, setState] = useState(() => {
    if (
      privateServer?.state === 'init' ||
      privateServer?.state === 'start' ||
      privateServer?.state === 'created'
    ) {
      return 'BOOTING';
    }
    if (privateServer?.state === 'running') {
      return 'RUNNING';
    }
    return 'INIT';
  });

  useEffect(() => {
    if (appStatus === ServerActionTypes.DEL_PRIVATE_SERVER_SUCCESS) {
      setState('DELETE_SUCCESS');
    }
  }, [appStatus]);

  useEffect(() => {
    if (startPolling && counter > 0) {
      const timer = setInterval(() => {
        let newCounter = counter - 1;
        if (newCounter === 0) {
          setCounter(10);
        } else {
          setCounter(newCounter);
        }
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [counter, startPolling]);

  useEffect(() => {
    if (currentUser.profile !== 'host') return;
    if (
      privateServer?.state === 'running' ||
      privateServer?.state === 'pending'
    ) {
      setState('RUNNING');
      setStartPolling(false);
      setCounter(0);
    }
    if (
      privateServer?.state === 'init' ||
      privateServer?.state === 'start' ||
      privateServer?.state === 'created'
    ) {
      setState('BOOTING');
      setStartPolling(true);
      setCounter(10);
    }
  }, [privateServer]);

  // always get private server to init the state
  useEffect(() => {
    if (currentUser.profile === 'host') getServer();
  }, [currentUser.profile]);

  const cancelAction = () => {
    setState('INIT');
  };

  const launchPrivateServer = () => {
    setState('START');
  };

  const startPrivateServer = region => {
    requestServer({ region });
    setState('BOOTING');
    setStartPolling(true);
    setCounter(10);
  };
  const deletePrivateServer = () => {
    deleteServer(privateServer?.IP);
  };

  if (!currentUser) {
    return null;
  }
  // for free user or cert profile user, prompt user to upgrade
  if (currentUser.type === 'free' || currentUser.profile === 'cert') {
    return (
      <>
        {upgrade === 'INIT' && (
          <Card>
            <CardHeader color="success" plain className={classes.cardTitle}>
              <FormattedMessage
                id="app.privateServer.title"
                defaultMessage="Private Servers"
              />
            </CardHeader>
            <CardBody>
              <Upgrade action={() => setUpgrade('UPGRADE')} />
            </CardBody>
          </Card>
        )}
        {upgrade === 'UPGRADE' && (
          <ChangePlan
            upgrade={true}
            onAction={() => {
              setUpgrade('INIT');
            }}
          />
        )}
      </>
    );
  }

  return (
    <Card>
      <CardHeader color="success" plain className={classes.cardTitle}>
        <FormattedMessage
          id="app.privateServer.title"
          defaultMessage="Private Servers"
        />
      </CardHeader>
      <CardBody>
        {/* Polling server status after launched */}
        <ServerStatus
          startPolling={startPolling}
          getServer={() => {
            getServer();
          }}
        />
        {state === 'INIT' && (
          <LaunchPrivateServer
            privateServer={privateServer}
            action={launchPrivateServer}
          />
        )}
        {state === 'START' && (
          <StartingPrivateServer
            currentRegion={currentRegion}
            action={startPrivateServer}
            cancelAction={cancelAction}
          />
        )}
        {state === 'DELETE' && (
          <DeletePrivateServer
            privateServer={privateServer}
            action={deletePrivateServer}
            cancelAction={() => setState('RUNNING')}
          />
        )}
        {state === 'DELETE_SUCCESS' && (
          <DeletePrivateServerCompleted
            privateServer={privateServer}
            action={() => {
              setState('INIT');
            }}
          />
        )}
        {state === 'BOOTING' && (
          <BootingPrivateServer counter={counter} getServer={getServer} />
        )}
        {privateServer && state === 'RUNNING' && (
          <>
            <PrivateServerStatus
              privateServer={privateServer}
              action={() => {
                setState('DELETE');
              }}
            />
            <ServerItem privateServer={privateServer} lang={lang} />
          </>
        )}
      </CardBody>
    </Card>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  publicServers: selectPublicServers,
  privateServer: selectPrivateServer,
  currentRegion: selectRegion,
  appStatus: selectStatus,
  lang: selectLang,
});

const mapDispatchToProps = dispatch => ({
  getServer: () => dispatch(getServerStart()),
  resetStatus: () => dispatch(clearError()),
  requestServer: ({ region }) => dispatch(requestServerStart({ region })),
  deleteServer: IP => dispatch(deleteServerStart(IP)),
  getCurrentRegion: () => dispatch(getRegionStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateServerCard);
