import React from 'react';
// @material-ui/core components
import { makeStyles, Theme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { FormattedMessage } from 'gatsby-plugin-intl';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import { getContinentsDisplayName, getRegionDisplayName } from 'utils/Regions';
import { selectLang } from '@allconnect/store/lib/appStatus/appStatus.selectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(1),
  },
  text: {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: '0 10px',
  },
}));

const RegionChip = ({ lang, selected, regions, onSelect }) => {
  const classes = useStyles();
  const Chips = regions.map(region => {
    const { name, continentCode, description, displayName } = region;
    return (
      <Tooltip key={`chip-${name}`} title={description}>
        <Chip
          className={classes.chip}
          avatar={<Avatar>{continentCode}</Avatar>}
          label={getRegionDisplayName(displayName, lang)}
          color={selected === name ? 'primary' : 'default'}
          onClick={() => {
            onSelect(name);
          }}
        />
      </Tooltip>
    );
  });
  return (
    <div>
      <Box my={1} className={classes.text}>
        <FormattedMessage
          id="app.privateServer.selectRegion"
          defaultMessage="Select Region Below"
        />
      </Box>
      <div className={classes.container}>{Chips}</div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  lang: selectLang,
});

export default connect(mapStateToProps, null)(RegionChip);
