/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';
import WebContext from 'store1/createContext';
import Input from '@material-ui/core/Input';
import Check from '@material-ui/icons/Check';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText } from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      padding: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    formControl: {
      margin: theme.spacing(1),
    },
    input: {
      marginRight: 10,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      width: 1,
      height: 28,
      margin: 4,
    },
  })
);

const phoneValidation = phone => {
  if (!phone) return false;
  const phoneNumber = parsePhoneNumberFromString(phone);
  if (phoneNumber) return phoneNumber.isValid();
  else return false;
};

function PhoneInput({
  form: { setFieldValue, errors, touched, setFieldError },
  field: { name, value },
  label,
}) {
  const classes = useStyles();
  const validPhone = phoneValidation(value);

  return (
    <>
      <div className={classes.container}>
        <InputLabel className={classes.input}>{label}</InputLabel>
        <ReactPhoneInput
          containerStyle={{ fontFamily: 'inherit' }}
          inputStyle={{
            width: 250,
          }}
          defaultCountry={'us'}
          preferredCountries={['us', 'ca']}
          autoFormat={true}
          disableDropdown
          value={value}
          onChange={phoneNumber => {
            setFieldValue(name, phoneNumber);
          }}
        />
        {validPhone && <Check color="primary" />}
      </div>
      {touched[name] && errors[name] ? (
        <FormHelperText id="component-error-text">
          {errors[name]}
        </FormHelperText>
      ) : null}
    </>
  );
}

PhoneInput.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  label: PropTypes.string,
};

export default PhoneInput;
