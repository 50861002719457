import React from 'react';
import { FormattedMessage, navigate } from 'gatsby-plugin-intl';
import { makeStyles } from '@material-ui/core/styles';

import { GridContainer } from '@jackywxd/shared-material-ui';
import { GridItem } from '@jackywxd/shared-material-ui';
import { Parallax } from '@jackywxd/shared-material-ui';
import Button from 'components/CustomButtons';
import Layout from 'components/Layout';
import landingPageStyle from 'assets/jss/material-kit-react/views/landingPage';

const useStyles = makeStyles(landingPageStyle);

const NotFoundPage = () => {
  const classes = useStyles();
  return (
    <Layout>
      <Parallax filter style={{ background: 'rgba(66, 133, 243, 0.6)' }}>
        <div className={classes.container}>
          <GridItem>
            <GridContainer justify="center" alignItems="center">
              <div style={{ textAlign: 'center' }}>
                <h1>
                  <FormattedMessage id="404.title" defaultMessage="NOT FOUND" />
                </h1>
                <p>
                  <FormattedMessage
                    id="404.text"
                    defaultMessage="You just hit a route that doesn't exist... the sadness."
                  />
                </p>
                <Button
                  round
                  onClick={() => {
                    navigate('/');
                  }}
                >
                  <FormattedMessage
                    id="404.button"
                    defaultMessage="Return Home"
                  />
                </Button>
              </div>
            </GridContainer>
          </GridItem>
        </div>
      </Parallax>
    </Layout>
  );
};

export default NotFoundPage;
