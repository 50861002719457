import React from 'react';
import { Router, Redirect } from '@reach/router';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PrivateRoute from 'components/PrivateRoute';
import { selectCurrentUser } from '@allconnect/store/lib/user/user.selectors';
import NotFound from 'pages/404';
import Default from 'app';
import Home from 'app/Home/Home';
import ProfileXml from 'app/ClientCard/ProfileXml';

const Application = () => {
  return (
    <Router>
      <PrivateRoute path="home" component={Home} />
      <PrivateRoute path="profilexml" component={ProfileXml} />
      <NotFound default />
    </Router>
  );
};

const App = () => {
  return (
    <Router>
      <Application path="/en/app/*" />
      <Application path="/zh-CN/app/*" />
      <Application path="/zh-TW/app/*" />
    </Router>
  );
};
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps, null)(App);
