import React from 'react';
// @material-ui/core components
import { FormattedMessage, navigate } from 'gatsby-plugin-intl';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Button from 'components/CustomButtons';

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: '10px',
  },
}));

// UI that allows user to launch a new Private Server
const Upgrade = ({ action }) => {
  const classes = useStyles();
  return (
    <>
      <List>
        <ListItem style={{ justifyContent: 'center' }}>
          <Typography className={classes.text}>
            <FormattedMessage
              id="app.privateServer.upgrade"
              defaultMessage="Upgrade to own your private server!"
            />
          </Typography>
          <Button round color="google" onClick={action}>
            <FormattedMessage id="nav.upgrade" defaultMessage="UPGRADE" />
          </Button>
        </ListItem>
      </List>
    </>
  );
};

export default Upgrade;
