import React from 'react';
// @material-ui/core components
import { FormattedMessage } from 'gatsby-plugin-intl';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

import Button from 'components/CustomButtons';

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: '10px',
  },
}));

// UI that allows user to launch a new Private Server
const LaunchPrivateServer = ({ action, privateServer }) => {
  const classes = useStyles();
  const name = privateServer?.name ? privateServer.name : '';
  const lgScreen = useMediaQuery('(min-width:500px)');
  return (
    <>
      <List>
        <ListItem style={{ justifyContent: 'center' }}>
          <Typography className={classes.text}>
            <FormattedMessage
              id="app.privateServer.launch"
              defaultMessage="Click Start to launch your server {name}"
              values={{ name }}
            />
          </Typography>
          <Button
            justIcon={lgScreen ? false : true}
            round
            color="google"
            onClick={action}
          >
            {lgScreen ? (
              <FormattedMessage
                id="app.privateServer.start"
                defaultMessage="START"
              />
            ) : (
              <PowerSettingsNewIcon />
            )}
          </Button>
        </ListItem>
      </List>
    </>
  );
};

export default LaunchPrivateServer;
