import React, { useState, useEffect } from 'react';
import { FormattedDate, FormattedMessage, navigate } from 'gatsby-plugin-intl';
// @material-ui/core components
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter';
import Button from 'components/CustomButtons';

import useStyles from './styles';
import Box from '@material-ui/core/Box';

const CancelSubscription = ({ plan, onAction }) => {
  const classes = useStyles();
  const lgScreen = useMediaQuery('(min-width:500px)');

  // user cancel subscription
  return (
    <Card>
      <CardHeader color="success" plain className={classes.cardTitle}>
        <FormattedMessage
          id="app.profile.cancelSub"
          defaultMessage="Cancel subscription"
        />
      </CardHeader>
      <CardBody>
        <Typography className={classes.text}>
          <FormattedMessage
            id="app.profile.confirmCancel"
            defaultMessage="Are you sure want to cancel your subscription {plan}?"
            values={{ plan }}
          />
        </Typography>
      </CardBody>
      <CardFooter className={classes.footer}>
        <Box mx={2}>
          <Button
            onClick={() => {
              onAction('CANCEL_CONFIRM');
            }}
            round
            type="submit"
            color="google"
          >
            <FormattedMessage id="button.submit" defaultMessage="Submit" />
          </Button>
        </Box>
        <Box mx={2}>
          <Button
            onClick={() => {
              onAction('INIT');
            }}
            round
            type="submit"
            color="primary"
          >
            <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
          </Button>
        </Box>
      </CardFooter>
    </Card>
  );
};

export default CancelSubscription;
