import React, { useEffect, useState } from 'react';
import { Link, FormattedMessage, navigate } from 'gatsby-plugin-intl';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { Parallax } from '@jackywxd/shared-material-ui';
import indexStyles from 'assets/jss/material-kit-react/views/landingPage';
import Layout from 'components/Layout';

import copy from 'clipboard-copy';
import xml2js from 'xml2js';
import { Regions } from '@allconnect/models/lib/regions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '@allconnect/store/lib/user/user.selectors';
import { selectPrivateServer } from '@allconnect/store/lib/server/server.selectors';
import Tooltip from '@material-ui/core/Tooltip';
import { conatinerFluid } from 'assets/jss/material-kit-react.jsx';

const allRegions = Regions.allRegions();
const useStyles = makeStyles(indexStyles);

const DownloadProfileXml = ({ currentUser, privateServer }) => {
  if (!currentUser) {
    return null;
  }
  const [copied, setCopied] = useState(false);
  const allContinetCodes = Regions.allContinent().sort((a, b) => {
    if (a > b) return 1;
    if (b > a) return -1;
  });

  let HostEntry = [];

  if (privateServer) {
    HostEntry.push({
      HostName: `Private: ${privateServer.name}`,
      HostAddress: privateServer.name,
    });
  }

  allContinetCodes.map(c => {
    const regions: any = [];
    allRegions.map(region => {
      const r = new Regions(region.name);
      if (region.continentCode === c) {
        regions.push({
          name: region.name,
          displayName: r.displayName(),
          continentCode: r.region.continentCode,
          description: r.region.description,
          countryCode: r.region.countryCode,
        });
      }
    });
    regions.map(region => {
      HostEntry.push({
        HostName: `Public: ${region.displayName}`,
        HostAddress: `${region.name}.getallconnect.com`,
      });
    });
  });
  const builder = new xml2js.Builder();
  const AnyConnectProfile = {
    $: {
      xmlns: 'http://schemas.xmlsoap.org/encoding/',
      'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
      'xsi:schemaLocation':
        'http://schemas.xmlsoap.org/encoding/ AnyConnectProfile.xsd',
    },
    ServerList: { HostEntry },
  };
  const serverListXml = builder.buildObject(AnyConnectProfile);

  const regex = /root/gi;
  const xml = serverListXml.replace(regex, 'AnyConnectProfile');
  if (!xml) {
    return null;
  }
  const classes = useStyles();
  return (
    <Layout>
      <Parallax small filter style={{ background: 'rgba(66, 133, 243, 0.6)' }}>
        <div className={classes.container}>
          <h1 className={classes.title}>
            <FormattedMessage
              id="app.profileXml"
              defaultMessage="Update Profile.Xml"
            />
          </h1>
          <br />
        </div>
      </Parallax>
      <main className={classNames(classes.main, classes.mainRaised)}>
        <Box
          style={{ ...conatinerFluid, textAlign: 'initial' }}
          color="text.primary"
        >
          <p>
            <FormattedMessage
              id="app.profileXml.desc"
              defaultMessage="
            Update profile.xml at local PC with below latest VPN servers list.
            Click on below text to copy the content to clipboard. Check out more
            details at online Help.
          "
            />
          </p>
          <Tooltip
            title={
              copied ? (
                <FormattedMessage id="app.vpnUser.copied" />
              ) : (
                <FormattedMessage id="app.vpnUser.clickCopy" />
              )
            }
          >
            <Box
              onClick={() => {
                setCopied(true);
                copy(xml);
              }}
            >
              <TextField
                label="Profile Xml"
                fullWidth
                multiline
                type="xml"
                rows="8"
                name="xml"
                value={xml}
                margin="normal"
                variant="outlined"
                InputProps={{ readOnly: true }}
              />
            </Box>
          </Tooltip>
        </Box>
      </main>
    </Layout>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  privateServer: selectPrivateServer,
});

export default connect(mapStateToProps, null)(DownloadProfileXml);
