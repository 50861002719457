import React from 'react';
// @material-ui/core components
import { FormattedMessage, navigate } from 'gatsby-plugin-intl';
import { makeStyles } from '@material-ui/core/styles';
// core components

import { GridContainer } from '@jackywxd/shared-material-ui';
import { GridItem } from '@jackywxd/shared-material-ui';
import { Parallax } from '@jackywxd/shared-material-ui';
import landingPageStyle from 'assets/jss/material-kit-react/views/landingPage';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '@allconnect/store/lib/user/user.selectors';
import Layout from 'components/Layout';
// import AppLayout from 'app/Layouts/AppLayout';
import ClientCard from '../ClientCard';
import ServerCard from '../ServerCard';
import PrivateServerCard from '../ServerCard/PrivateServer/PrivateServerCard';
import VpnUserCard from '../VpnUserCard';
import ProfileCard from '../ProfileCard';
import { isDesktop } from 'components/Utils';
import Button from 'components/CustomButtons';
import { SlackChat } from 'components/SlackChat';

const useStyle = makeStyles(landingPageStyle);

// FreeRegister component
const AppHome = ({ currentUser }) => {
  const classes = useStyle();

  if (!currentUser || !currentUser.email) {
    return null;
  }

  return (
    <Layout>
      <Parallax filter small style={{ background: 'rgba(66, 133, 243, 0.6)' }}>
        <div className={classes.container}>
          <h1 className={classes.title} style={{ color: '#fff' }}>
            {currentUser.name ? (
              <>
                <FormattedMessage id="app.welcome" defaultMessage={'Welcome'} />{' '}
                {currentUser.name}
              </>
            ) : (
              <>
                <FormattedMessage id="app.welcome" defaultMessage={'Welcome'} />{' '}
                {currentUser.email}
              </>
            )}
          </h1>
          <br />
          <br />
        </div>
      </Parallax>
      <div className={isDesktop() ? classes.mainRaised : null}>
        <div className={isDesktop() ? classes.container : null}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={10} md={7}>
              <ProfileCard />
            </GridItem>
            <GridItem xs={12} sm={10} md={7}>
              <VpnUserCard />
            </GridItem>
            <GridItem xs={12} sm={10} md={7}>
              <PrivateServerCard />
            </GridItem>
            <GridItem xs={12} sm={10} md={7}>
              <ServerCard />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps, null)(AppHome);
