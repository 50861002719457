import React from 'react';
// @material-ui/core components
import Box from '@material-ui/core/Box';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { Typography } from '@material-ui/core';
import Button from 'components/CustomButtons';
import useStyles from './styles';

const DeletePrivateServer = ({ privateServer, action, cancelAction }) => {
  const classes = useStyles();
  if (!privateServer) {
    return null;
  }
  const { name, region } = privateServer;

  return (
    <>
      <div className={classes.text}>
        <Typography>
          <FormattedMessage
            id="app.privateServer.delete"
            defaultMessage="Delete server: {name} at region: {region}?"
            values={{ name, region }}
          />
        </Typography>
      </div>
      <Box display="flex" justifyContent="center" className={classes.text}>
        <Box mx={2}>
          <Button round color="google" onClick={action}>
            <FormattedMessage id="button.submit" defaultMessage="Confirm" />
          </Button>
        </Box>
        <Box mx={2}>
          <Button round color="primary" onClick={cancelAction}>
            <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default DeletePrivateServer;
