import React, { useState, useEffect } from 'react';
import { FormattedDate, FormattedMessage, navigate } from 'gatsby-plugin-intl';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import EditIcone from '@material-ui/icons/Edit';
import ExitToApp from '@material-ui/icons/ExitToApp';

// core components
import * as yup from 'yup';
import { Formik } from 'formik';

import { CustomInput } from '@jackywxd/shared-material-ui';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter';

import Button from 'components/CustomButtons';

import useStyles from './styles';

// Show user Account data
const AccountCard = ({ currentUser, onAction }) => {
  const classes = useStyles();
  const lgScreen = useMediaQuery('(min-width:500px)');

  if (!currentUser) {
    return null;
  }
  const {
    email,
    type,
    status,
    plan,
    profile,
    expiredAt,
    subscription,
    last4,
  } = currentUser;

  return (
    <Card>
      <CardHeader color="success" plain className={classes.cardTitle}>
        <FormattedMessage
          id="app.profile.accountSetting"
          defaultMessage="Account Setting"
        />
      </CardHeader>
      <CardBody>
        <List className={classes.ul}>
          <ListItem>
            <ListItemText
              primary={
                <FormattedMessage
                  id="app.profile.currentPlan"
                  defaultMessage="Current Plan:"
                />
              }
              secondary={
                type !== 'free' && plan
                  ? plan.toUpperCase()
                  : profile.toUpperCase()
              }
            />

            {status === 'subscribed' ? (
              <>
                <Button
                  justIcon={lgScreen ? false : true}
                  color="google"
                  round
                  onClick={() => {
                    onAction('CANCEL_START');
                  }}
                >
                  {lgScreen ? (
                    <FormattedMessage id="button.cancel" />
                  ) : (
                    <CancelIcon />
                  )}
                </Button>
                <Button
                  justIcon={lgScreen ? false : true}
                  color="google"
                  round
                  onClick={() => {
                    onAction('CHANGE_PLAN');
                  }}
                >
                  {lgScreen ? (
                    <FormattedMessage
                      id="app.profile.change"
                      defaultMessage="Change"
                    />
                  ) : (
                    <AddIcon />
                  )}
                </Button>
              </>
            ) : (
              <Button
                justIcon={lgScreen ? false : true}
                color="google"
                round
                onClick={() => {
                  navigate('/upgrade');
                }}
              >
                {lgScreen ? (
                  <FormattedMessage
                    id="app.profile.subOrPurchase"
                    defaultMessage="subscribe"
                  />
                ) : (
                  <AddIcon />
                )}
              </Button>
            )}
          </ListItem>
          {last4 && ( // credit card
            <ListItem>
              <ListItemText
                primary={
                  <FormattedMessage
                    id="app.profile.creditcard"
                    defaultMessage="Credit Card:"
                  />
                }
                secondary={<>End with: {last4}</>}
              />
              <Button justIcon={lgScreen ? false : true} round>
                {lgScreen ? (
                  <FormattedMessage
                    id="button.update"
                    defaultMessage="UPDATE"
                  />
                ) : (
                  <EditIcone />
                )}
              </Button>
            </ListItem>
          )}
          <ListItem>
            {type !== 'free' ? (
              <ListItemText
                primary={
                  subscription && status === 'subscribed' ? (
                    <FormattedMessage
                      id="app.profile.autoRenew"
                      defaultMessage="Auto Renew:"
                    />
                  ) : (
                    <FormattedMessage
                      id="app.profile.expiration"
                      defaultMessage="Expiration:"
                    />
                  )
                }
                secondary={
                  <FormattedDate
                    value={new Date(expiredAt).toLocaleDateString()}
                  />
                }
              />
            ) : (
              expiredAt &&
              new Date() < new Date(expiredAt) && (
                <ListItemText
                  primary={
                    <FormattedMessage
                      id="app.profile.expiration"
                      defaultMessage="Expiration:"
                    />
                  }
                  secondary={
                    <FormattedDate
                      value={new Date(expiredAt).toLocaleDateString()}
                    />
                  }
                />
              )
            )}
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <FormattedMessage
                  id="app.profile.loginUser"
                  defaultMessage="Login User:"
                />
              }
              secondary={email}
            />
            <Button
              justIcon={lgScreen ? false : true}
              color="google"
              round
              onClick={() => {
                onAction('CHANGE_PASSWORD_START');
              }}
            >
              {lgScreen ? (
                <FormattedMessage id="app.profile.changePassword" />
              ) : (
                <EditIcone />
              )}
            </Button>
            <Button
              round
              justIcon={lgScreen ? false : true}
              onClick={() => {
                navigate('/signout');
              }}
            >
              {lgScreen ? (
                <FormattedMessage id="nav.signout" defaultMessage="Sign out" />
              ) : (
                <ExitToApp />
              )}
            </Button>
          </ListItem>
        </List>
      </CardBody>{' '}
      <CardFooter className={classes.footer}>
        {
          /* <Button
          color="primary"
          round
          onClick={() => {
            setEditProfile('START');
          }}
        >
          <FormattedMessage
            id="app.profile.editProfile"
            defaultMessage="EDIT PROFILE"
          />
        </Button> */ ' '
        }
      </CardFooter>
    </Card>
  );
};

export default AccountCard;
