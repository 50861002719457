import React, { useState, useEffect } from 'react';
import { FormattedDate, FormattedMessage, navigate } from 'gatsby-plugin-intl';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import EditIcone from '@material-ui/icons/Edit';

// core components
import * as yup from 'yup';
import { Formik } from 'formik';

import { CustomInput } from '@jackywxd/shared-material-ui';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter';

// import { Button } from '@jackywxd/shared-material-ui';
import workStyle from 'assets/jss/material-kit-react/views/landingPageSections/workStyle';
import loginPageStyle from 'assets/jss/material-kit-react/views/loginPage';

import Button from 'components/CustomButtons';
import {
  onChangePasswordStart,
  cancelSubscriptionStart,
} from '@allconnect/store/lib/user/user.actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '@allconnect/store/lib/user/user.selectors';
import { selectStatus } from '@allconnect/store/lib/appStatus/appStatus.selectors';
import InputAdornment from '@material-ui/core/InputAdornment';
// @material-ui/icons
import SendIcon from '@material-ui/icons/Send';
import UserActionTypes from '@allconnect/store/lib/user/user.types';
import { clearError } from '@allconnect/store/lib/appStatus/appStatus.actions';
import useStyles from './styles';

import EditProfile from './EditProfile';
import AccountCard from './AccountCard';
import CancelSubscription from './CancelSubscription';
import CancelCompleted from './CancelCompleted';
import ChangePassword from './ChangePassword';
import ChangePasswordCompleted from './ChangePasswordCompleted';
import ChangePlan from './ChangePlan';

// FreeRegister component
const ProfileCard = ({
  currentUser,
  appStatus,
  changePasswordStart,
  resetStatus,
  cancelSubscription,
}) => {
  const classes = useStyles();
  const [changePassword, setChangePassword] = useState(null);
  const [cancel, setCancel] = useState(null);
  const [editProfile, setEditProfile] = useState(null);
  const lgScreen = useMediaQuery('(min-width:500px)');

  const [state, setState] = useState('INIT');
  if (!currentUser) {
    return null;
  }
  const {
    email,
    type,
    status,
    plan,
    profile,
    expiredAt,
    subscription,
    last4,
  } = currentUser;

  let trialEnd;
  if (subscription) {
    const { trial_end, status } = subscription;
    if (status === 'trialing' && trial_end) {
      trialEnd = trial_end;
    }
  }

  useEffect(() => {
    if (appStatus === UserActionTypes.CHANGE_PASSWORD_SUCCESS) {
      resetStatus();
      setState('CHANGE_PASSWORD_COMPLETED');
    }
    if (appStatus === UserActionTypes.CANCEL_SUBSCRIPTION_SUCCESS) {
      resetStatus();
      setState('CANCEL_COMPLETED');
    }
  }, [appStatus]);

  if (editProfile) {
    return (
      <EditProfile editProfile={editProfile} setEditProfile={setEditProfile} />
    );
  }

  const onAction = (action, values) => {
    if (action === 'CANCEL_CONFIRM') {
      cancelSubscription();
    } else if (action === 'CHANGE_PASSWORD_CONFIRM') {
      changePasswordStart(values);
    } else if (action === 'CHANGE_PASSWORD_COMPLETED') {
      //
    } else {
      setState(action);
    }
  };

  return (
    <>
      {state === 'INIT' && (
        <AccountCard currentUser={currentUser} onAction={onAction} />
      )}
      {state === 'CHANGE_PLAN' && (
        <ChangePlan upgrade={false} onAction={onAction} />
      )}
      {state === 'CANCEL_START' && (
        <CancelSubscription plan={currentUser.plan} onAction={onAction} />
      )}
      {state === 'CANCEL_COMPLETED' && <CancelCompleted onAction={onAction} />}
      {state === 'CHANGE_PASSWORD_START' && (
        <ChangePassword onAction={onAction} />
      )}
      {state === 'CHANGE_PASSWORD_COMPLETED' && (
        <ChangePasswordCompleted onAction={onAction} />
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  appStatus: selectStatus,
});

const mapDispatchToProps = dispatch => ({
  resetStatus: () => dispatch(clearError()),
  cancelSubscription: () => dispatch(cancelSubscriptionStart()),
  changePasswordStart: ({ oldPassword, newPassword }) =>
    dispatch(onChangePasswordStart({ oldPassword, newPassword })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCard);
