import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
// core components
import * as yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Divider from '@material-ui/core/Divider';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectCurrentUser,
  selectPaymentIntent,
} from '@allconnect/store/lib/user/user.selectors';
import {
  selectEmail,
  selectUser,
  selectPaymentRequest,
} from '@allconnect/store/lib/payment/payment.selectors';
import {
  paymentSetEmail,
  paymentSetPaymentRequest,
  paymentReset,
} from '@allconnect/store/lib/payment/payment.actions';
import {
  signupStart,
  clearPaymentIntent,
  emailSignInStart,
  onPaymentIntentStart,
  changeSubscriptionStart,
  loadUserStart,
  addSubscriptionStart,
} from '@allconnect/store/lib/user/user.actions';
import { clearError } from '@allconnect/store/lib/appStatus/appStatus.actions';
import {
  selectStatus,
  selectLang,
} from '@allconnect/store/lib/appStatus/appStatus.selectors';
import UserActionTypes from '@allconnect/store/lib/user/user.types';
import Button from 'components/CustomButtons';
import useStyles from './styles';
import Box from '@material-ui/core/Box';
import pricing from '../../../content/pricing/pricing.json';

// Change User Subscription component
const ChangePlan = ({
  lang,
  appStatus,
  currentUser,
  onAction,
  upgrade,
  resetApp,
  changePlan,
}) => {
  const classes = useStyles();
  const [state, setState] = useState('INIT');
  const [value, setValue] = React.useState(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  const [plans, setPlans] = useState(() => {
    if (lang) {
      const ps = pricing.personal[lang].plans;
      if (upgrade) return ps.filter(p => p.profile === 'host');
      else return ps;
    } else return {};
  });

  useEffect(() => {
    if (!lang) {
      return;
    }
    const ps = pricing.personal[lang].plans;
    if (upgrade) {
      setPlans(ps.filter(p => p.profile === 'host'));
    } else setPlans(ps);
  }, [lang]);

  useEffect(() => {
    if (appStatus === UserActionTypes.CHANGE_SUBSCRIPTION_SUCCESS) {
      console.log(`Subscription changed successfully`);
      setState('COMPLETED');
    } else if (appStatus === UserActionTypes.CHANGE_SUBSCRIPTION_FAILURE) {
      console.log(`Failed to change`);
      setState('FAILED');
    }
  }, [appStatus]);

  const onSubmit = () => {
    changePlan(value);
  };
  return (
    <Card>
      <CardHeader color="primary" plain className={classes.cardTitle}>
        <FormattedMessage
          id="app.change.title"
          defaultMessage="Change Subscription Plan"
        />
      </CardHeader>
      <CardBody className={classes.text}>
        {state === 'INIT' && (
          <FormControl component="fieldset">
            <Box my={1}>
              <FormattedMessage
                id="app.change.subtitle"
                defaultMessage="Change Subscription Plan"
              />
            </Box>
            <Divider />
            <RadioGroup
              aria-label="plan"
              name="Plan"
              value={value}
              onChange={handleChange}
            >
              {plans.map((plan, index) => {
                if (plan.plan !== currentUser.plan)
                  return (
                    <FormControlLabel
                      key={plan.plan}
                      id={index}
                      value={plan.plan}
                      control={<Radio />}
                      label={`${plan.title}: ${plan.price}${plan.currency}`}
                    />
                  );
              })}
            </RadioGroup>
          </FormControl>
        )}
        {state === 'COMPLETED' && (
          <Box my={2}>
            <FormattedMessage
              id="app.change.success"
              defaultMessage="Subscription plan changed successfully"
            />{' '}
            {plans[value]?.title}
          </Box>
        )}
        {state === 'FAILED' && (
          <Box my={2}>
            <FormattedMessage
              id="app.change.failure"
              defaultMessage="Subscription plan changed successfully"
            />
          </Box>
        )}
      </CardBody>
      <CardFooter className={classes.footer}>
        <Box mx={2}>
          {state === 'INIT' && (
            <Button onClick={onSubmit} round type="submit" color="google">
              <FormattedMessage id="button.submit" />
            </Button>
          )}
        </Box>
        <Box mx={2}>
          <Button
            onClick={() => {
              resetApp();
              onAction('INIT');
            }}
            round
            type="submit"
            color="primary"
          >
            {state === 'INIT' ? (
              <FormattedMessage id="button.cancel" />
            ) : (
              <FormattedMessage id="button.ok" />
            )}
          </Button>
        </Box>
      </CardFooter>
    </Card>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  appStatus: selectStatus,
  paymentIntent: selectPaymentIntent,
  email: selectEmail,
  user: selectUser,
  lang: selectLang,
  paymentRequest: selectPaymentRequest,
});

const mapDispatchToProps = dispatch => ({
  changePlan: plan => dispatch(changeSubscriptionStart({ plan })),
  clearPaymentIntent: () => dispatch(clearPaymentIntent()),
  resetPayment: () => dispatch(paymentReset()),
  resetApp: () => dispatch(clearError()),
  paymentSetEmail: email => dispatch(paymentSetEmail(email)),
  setPaymentRequest: ({ plan, paymentRequest }) =>
    dispatch(paymentSetPaymentRequest({ plan, paymentRequest })),
  addSubscription: ({ plan, token, paymentMethod, name, phone }) =>
    dispatch(addSubscriptionStart({ plan, token, paymentMethod, name, phone })),
  loadUser: () => dispatch(loadUserStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePlan);
