import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import ReactCountryFlag from 'react-country-flag';
import { isMobile } from 'react-device-detect';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Button from 'components/CustomButtons';
import QRCode from 'components/QRCode';
import { Regions } from '@allconnect/models/lib/regions';
import { getRegionDisplayName } from 'utils/Regions';
import useStyles from './styles';
import Box from '@material-ui/core/Box';

const allRegions = Regions.allRegions();

function ServerItem({ privateServer, lang }) {
  if (!privateServer) {
    return null;
  }
  const classes = useStyles();
  const {
    region: currentRegion,
    name: hostName,
    owner: userName,
  } = privateServer;

  const region = allRegions.filter(r => r.name === currentRegion)[0];
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const deeplink = `anyconnect:connect?host=${hostName}&prefill_username=${userName}`;

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <ReactCountryFlag
            svg
            countryCode={region.countryCode}
            style={{
              fontSize: '2em',
              lineHeight: '2em',
            }}
          />
        </ListItemIcon>
        <Box width="100%" className={classes.server}>
          <ListItemText
            primary={getRegionDisplayName(region.displayName, lang)}
            secondary={hostName}
          />
        </Box>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {isMobile ? (
            <Button
              round
              color="google"
              onClick={() => {
                window.location = deeplink;
              }}
            >
              <FormattedMessage
                id="app.privateServer.launchAnyConnect"
                defaultMessage="Launch AnyConnect"
              />
            </Button>
          ) : (
            <QRCode url={deeplink} />
          )}
        </div>
      </Collapse>
    </>
  );
}

export default ServerItem;
