import React, { useState, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import * as QRCode from 'qrcode.react';
import { paymentSetSource } from '@allconnect/store/lib/payment/payment.actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectSource } from '@allconnect/store/lib/payment/payment.selectors';

const QR = ({ url }) => {
  return <QRCode value={url} />;
};

export default QR;
