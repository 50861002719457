import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
  },
  cardTitle: {
    textDecoration: 'none',
    fontSize: '1.1rem',
    fontWeight: 500,
    fontFamily: '"Roboto Slab", "Times New Roman", serif',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  footer: {
    justifyContent: 'center',
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  button: {
    margin: theme.spacing(1),
    flex: 1,
  },
  text: {
    color: theme.palette.text.primary,
  },
  root: {
    // display: 'flex',
    // flexDirection: 'column',
    width: '100%',
    margin: '0 auto',
  },
  listSection: {
    color: theme.palette.text.secondary,
    listStyle: 'none',
    backgroundColor: 'inherit',
    // display: 'flex',
    // flexFlow: 'wrap',
    width: '100%',
  },
  ul: {
    color: theme.palette.text.primary,
    listStyle: 'none',
    backgroundColor: 'inherit',
    padding: 0,
  },
  inline: {
    // display: 'inline',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default useStyles;
