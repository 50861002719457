const ContinentsTable = {
  NA: {
    en: 'NA',
    'zh-CN': '北美地区',
    'zh-TW': '北美地區',
  },
  EU: {
    en: 'EU',
    'zh-CN': '欧盟地区',
    'zh-TW': '歐盟地區',
  },
  AP: {
    en: 'AP',
    'zh-CN': '亚太地区',
    'zh-TW': '亞太地區',
  },
};

const RegionsTable = {
  Virginia: { en: 'Virginia', 'zh-CN': '维吉尼亚', 'zh-TW': '維吉尼亞' },
  Ohio: { en: 'Ohio', 'zh-CN': '俄亥俄', 'zh-TW': '俄亥俄' },
  Oregon: { en: 'Oregon', 'zh-CN': '俄勒冈', 'zh-TW': '俄勒岡' },
  Ireland: { en: 'Ireland', 'zh-CN': '爱尔兰', 'zh-TW': '愛爾蘭' },
  London: { en: 'London', 'zh-CN': '伦敦', 'zh-TW': '倫敦' },
  Paris: { en: 'Paris', 'zh-CN': '巴黎', 'zh-TW': '巴黎' },
  Frankfurt: { en: 'Frankfurt', 'zh-CN': '法兰克福', 'zh-TW': '法蘭克福' },
  Singapore: { en: 'Singapore', 'zh-CN': '新加坡', 'zh-TW': '新加坡' },
  Sydney: { en: 'Sydney', 'zh-CN': '悉尼', 'zh-TW': '悉尼' },
  Tokyo: { en: 'Tokyo', 'zh-CN': '东京', 'zh-TW': '東京' },
  Seoul: { en: 'Seoul', 'zh-CN': '汉城', 'zh-TW': '漢城' },
  Mumbai: { en: 'Mumbai', 'zh-CN': '孟买', 'zh-TW': '孟買' },
  Montreal: { en: 'Montreal', 'zh-CN': '蒙特利尔', 'zh-TW': '蒙特利爾' },
};

export function getContinentsDisplayName(
  name: 'NA' | 'AP' | 'EU',
  lang: 'en' | 'zh-CN' | 'zh-TW'
) {
  return ContinentsTable[name][lang];
}

export function getRegionDisplayName(
  region: keyof typeof RegionsTable,
  lang: 'en' | 'zh-CN' | 'zh-TW'
) {
  return RegionsTable[region][lang];
}
